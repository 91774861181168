import React from "react";

import './AboutTimeline.css';

import cadmusImg from "../images/cadmus-img.png";
import gtImg from "../images/gt-img.png";
import createxImg from "../images/createx-img.png";
import devImg from "../images/dev-img.png";
import nghsImg from "../images/nghs-img.png";
import productImg from "../images/product-img.png";
import piilotsImg from "../images/pilots-img.png";

import projectPoster from "../images/project-poster.png";
import finalReport from "../images/final-report.pdf";

const events = [
    {
        index: 0,
        title: "Our Beginnings",
        desc: "CADMUS was founded from a determined, multidisciplinary group of students looking to make a difference.",
        date1: "WINTER",
        date2: "2023",
        img: cadmusImg
    },
    {
        index: 1,
        title: "Engineering Capstone Project ",
        desc: "We put in a lot of work to make our first tool, a wearable device for concurrent, voice notetaking.",
        date1: "SPRING",
        date2: "2023",
        img: gtImg,
        links: [
            ["project poster", "https://drive.google.com/file/d/1sHV-arkwNX2eb25rNZfo6ARu2sUh9XDL/view?usp=sharing"],
            ["final report", "https://drive.google.com/file/d/1dcKaBJkRT41ITtU2rA9w6zaJu75OUASA/view?usp=sharing"]
        ]
    },
    {
        index: 2,
        title: "Create X Startup Launch",
        desc: "To support the continuation of the project, we founded a company and went though an accelerator.",
        date1: "SUMMER",
        date2: "2023",
        img: createxImg
    },
    {
        index: 3,
        title: "Development and Iteration",
        desc: "After further research and testing, we determined that a software solution would be more useful.",
        date1: "FALL",
        date2: "2023",
        img: devImg
    },
    {
        index: 4,
        title: "NGHS Partnership",
        desc: "We have partnered with the Northeast Georgia Health System to explore novel analytics techniques.",
        date1: "WINTER",
        date2: "2024",
        img: nghsImg
    },
    {
        index: 5,
        title: "Honing the Product",
        desc: "We have implemented our system with Habersham County EMS and NHGS for extensive field testing.",
        date1: "SPRING",
        date2: "2024",
        img: productImg
    },
    {
        index: 6,
        title: "Pilots",
        desc: "Join us in revolutionizing EMS quality – reach out for a pilot!",
        date1: "FALL",
        date2: "2024",
        img: piilotsImg
    }
]

const AboutTimeline = () => {
    return (
        <div className="about-timeline">
            <div className="content">
                <h2>Where We Came From</h2>
                <div className="section-content">
                    <div className="line">
                        <i className="fa-solid fa-flag flag"></i>
                        <div className="black-line"></div>
                        <div className="red-line"></div>
                    </div>

                    <div className="events">
                        {events.map((event) => (
                            <div className="event">
                                <img src={event.img} />
                                <div className="date1">
                                    {event.date1}
                                    <div className="date2-mobile">{event.date2}</div>
                                </div>
                                <i className="fa-solid fa-diamond diamond-black"></i>
                                <i className="fa-solid fa-diamond diamond-red"></i>
                                <div className="right-container">
                                    <div className="date2-desktop">{event.date2}</div>
                                    <div className="text">
                                        <h3>{event.title}</h3>
                                        <p>{event.desc}</p>
                                        {
                                            ("links" in event) ? (
                                                <span>
                                                    <span>View the </span>
                                                    <a target="_blank" href={event.links[0][1]}>{event.links[0][0]}</a>
                                                    <span> and the </span>
                                                    <a target="_blank" href={event.links[1][1]}>{event.links[1][0]}</a>
                                                    <span>.</span>
                                                </span>
                                            ) : (
                                                ""
                                            )
                                        }
                                    </div>
                                </div>
                            </div>
                        ))}
                    </div>

                </div>
            </div>
        </div>
    )
}

export default AboutTimeline;